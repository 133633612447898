exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-items-index-js": () => import("./../../../src/pages/items/index.js" /* webpackChunkName: "component---src-pages-items-index-js" */),
  "component---src-pages-items-pokemmo-slug-js": () => import("./../../../src/pages/items/{Pokemmo.slug}.js" /* webpackChunkName: "component---src-pages-items-pokemmo-slug-js" */),
  "component---src-pages-market-investments-js": () => import("./../../../src/pages/market/investments.js" /* webpackChunkName: "component---src-pages-market-investments-js" */),
  "component---src-pages-market-multigraph-js": () => import("./../../../src/pages/market/multigraph.js" /* webpackChunkName: "component---src-pages-market-multigraph-js" */),
  "component---src-pages-market-trade-ads-js": () => import("./../../../src/pages/market/trade-ads.js" /* webpackChunkName: "component---src-pages-market-trade-ads-js" */),
  "component---src-pages-tools-archetype-counter-js": () => import("./../../../src/pages/tools/archetype-counter.js" /* webpackChunkName: "component---src-pages-tools-archetype-counter-js" */),
  "component---src-pages-tools-berries-js": () => import("./../../../src/pages/tools/berries.js" /* webpackChunkName: "component---src-pages-tools-berries-js" */),
  "component---src-pages-tools-breeding-js": () => import("./../../../src/pages/tools/breeding.js" /* webpackChunkName: "component---src-pages-tools-breeding-js" */),
  "component---src-pages-tools-cosmetics-js": () => import("./../../../src/pages/tools/cosmetics.js" /* webpackChunkName: "component---src-pages-tools-cosmetics-js" */),
  "component---src-pages-tools-egg-moves-calculator-js": () => import("./../../../src/pages/tools/egg-moves-calculator.js" /* webpackChunkName: "component---src-pages-tools-egg-moves-calculator-js" */),
  "component---src-pages-tools-pokedex-js": () => import("./../../../src/pages/tools/pokedex.js" /* webpackChunkName: "component---src-pages-tools-pokedex-js" */)
}

